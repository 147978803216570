import React from "react";
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo";
import Navigation from "sections/00-Navigation";
import BannerSlider from "sections/BannerSlider";
import Service from "sections/Service";
import DaaIntro from "sections/02-DaaIntro";

import Awards from "sections/Awards";
import UserMap from "sections/UserMap";
import Section1AnimationRight from "sections/04-Section1AnimationRight";
import Statistics from "sections/Statistics";
import Stack from "sections/Stack";
import Faq from "sections/Faq";
import Footer from "sections/Footer";

import theme from "assets/theme/theme";
import GlobalStyle from "assets/theme";

const IndexPage = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Layout>
      <SEO title="Home" />
      <Navigation />
      <BannerSlider />
      <Service />
      <DaaIntro />
      <Awards />
      <UserMap />
      <Section1AnimationRight />
      <Statistics />
      <Stack />
      <Faq />
      <Footer />
    </Layout>
  </ThemeProvider>
)

export default IndexPage
