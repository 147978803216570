import React from "react";

import { SlickSlider, SliderItem } from "reusecore/SlickSlider";
import Banner from "sections/Banner";
import Intro from "sections/01-Intro";
import BannerThree from "sections/BannerThree";

import BannerSliderWrapper from "./bannerSlider.style";

const BannerSlider = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i) => <div>0 {i + 1} </div>,
  };
  return (
    <BannerSliderWrapper>
        <SlickSlider {...settings}>
          <SliderItem>
            <Banner />
          </SliderItem>
          <SliderItem>
            <Intro />
          </SliderItem>
          <SliderItem>
            <BannerThree />
          </SliderItem>
        </SlickSlider>
    </BannerSliderWrapper>
  );
};

export default BannerSlider;
